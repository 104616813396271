import React from 'react';

import { Button, createStyles, Group, Modal, type ModalProps } from '@mantine/core';
import constate from 'constate';

const [ModalControlProvider, useModalControlState] = constate(
	(modalControl: { onClose: ModalProps['onClose'] }) => {
		return modalControl;
	}
);

export const useModalControl = useModalControlState;

export function createModal<ContentProps extends object>(
	ModalContent: React.ComponentType<ContentProps>
) {
	const FullModal: React.FC<Omit<ModalProps, 'children'> & { contentProps?: ContentProps | null }> = ({
		contentProps = null,
		...modalProps
	}) => {
		return (
			<Modal {...modalProps}>
				<ModalControlProvider onClose={modalProps.onClose}>
					{/* NOTE: there is no check that contentProps is not null or undefined when modal opened, we rely on runtime errors during development */}
					{modalProps.opened ? <ModalContent {...(contentProps as ContentProps)} /> : null}
				</ModalControlProvider>
			</Modal>
		);
	};

	FullModal.displayName = `createModal(${ModalContent.displayName ?? ModalContent.name})`;

	return FullModal;
}

interface ModalFooterProps {
	onCancel?: () => void;
	onSubmit?: () => void;
	isDisabled?: boolean;
	isLoading?: boolean;
	cancelLabel?: string;
	submitLabel?: string;
	submitButtonColor?: string;
}

const useStyles = createStyles(({ colors }) => ({
	root: {
		display: 'flex',
		margin: '.5rem 0',
		padding: '.75rem .75rem .25rem',
		borderTopStyle: 'solid',
		borderTopWidth: 1,
		borderTopColor: colors.light[2],
	},
	button: {
		flex: 1,
	},
}));

// TODO: must be fixed on bottom
//	see https://github.com/orgs/mantinedev/discussions/1141#discussioncomment-7195745
//	Ask Anton first
export const ModalFooter: React.FC<ModalFooterProps> = ({
	onCancel,
	cancelLabel,
	isDisabled,
	isLoading,
	onSubmit,
	submitLabel,
	submitButtonColor,
}) => {
	const { classes } = useStyles();

	return (
		<Group className={classes.root} spacing=".75rem">
			{onCancel ? (
				<Button variant="light" className={classes.button} onClick={onCancel}>
					{cancelLabel || 'Cancel'}
				</Button>
			) : null}

			<Button
				className={classes.button}
				variant="filled"
				color={submitButtonColor}
				loading={isLoading}
				loaderProps={{ size: 14 }}
				disabled={isDisabled || isLoading}
				{...(onSubmit ? { onClick: onSubmit } : { type: 'submit' })}
			>
				{submitLabel || 'Submit'}
			</Button>
		</Group>
	);
};
